//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Default from "@/view/pages/partials/Default.vue";
export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    pageLoading: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      rowData: [],
      selectedRows: []
    };
  },
  components: {
    Status: Status,
    Default: Default
  },
  watch: {
    selectedRows: function selectedRows(param) {
      this.$emit("selectedRows", param);
    },
    customer: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.assignData();
      }
    }
  },
  methods: {
    selectCustomer: function selectCustomer(param) {
      this.$emit("selectCustomer", param);
    },
    assignData: function assignData() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.customer.persons) === false) {
        _this.rowData = _this.customer.persons;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.assignData();

    _this.$root.$on("clearSelection", function () {
      _this.selectedRows = [];
    });
  },
  beforeDestroy: function beforeDestroy() {
    /*this.$root.$off("clearSelection");*/
  },
  computed: {
    selectedAll: {
      set: function set(param) {
        this.selectedRows = [];

        if (param && this.rowData.length) {
          for (var i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get: function get() {
        var totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      }
    }
  }
};