<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="3" class="pl-0">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="get_all_equipment"
            @keydown.tab="get_all_equipment"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="get_all_equipment"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="new_equipment()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>New Equipment</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" align="center" width="100">Image</th>
            <th class="simple-table-th" v-if="!isCustomer" width="200">
              Customer
            </th>
            <th class="simple-table-th">Property</th>
            <th class="simple-table-th" width="100">Name</th>
            <th class="simple-table-th" width="100">Description</th>
            <th class="simple-table-th" width="100">Serial No.</th>
            <th class="simple-table-th" width="100">Model</th>
            <th class="simple-table-th" width="100">Brand</th>
            <th class="simple-table-th" width="100">Location</th>
            <th class="simple-table-th" width="100">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr
                  class="alternate-listing-row"
                  height="98px"
                  :key="index"
                  v-on:click="show_detail(row)"
                >
                  <td
                    height="98px"
                    align="center"
                    class="simple-table-td"
                    width="100"
                  >
                    <template v-if="row.image">
                      <v-img
                        class="customer-image"
                        width="70"
                        height="70"
                        contain
                        :lazy-src="$defaultImage"
                        :src="row.image"
                      >
                      </v-img>
                    </template>
                    <template v-else>
                      <v-img
                        class="customer-image"
                        width="70"
                        height="70"
                        contain
                        :lazy-src="$defaultImage"
                        :src="$defaultImage"
                      >
                      </v-img>
                    </template>
                  </td>
                  <td
                    height="98px"
                    v-if="!isCustomer"
                    class="simple-table-td"
                    width="200"
                  >
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Display Name: </b> {{ row.customer_display_name }}
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Company: </b> {{ row.customer_company_name }}
                    </p>
                  </td>
                  <td height="98px" class="simple-table-td">
                    {{ row.address_unit_no }}
                    {{ row.address_street_1 }}
                    {{ row.address_street_2 }}
                    {{ row.address_zip_code }}
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    {{ row.name }}
                    <v-chip
                      :content="`${row.warranty_duration} ${row.warranty_type}(s)`"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                      }"
                      x-small
                      label
                      text-color="white"
                      color="cyan"
                      v-if="row.warranty"
                    >
                      Warranty
                    </v-chip>
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    {{ row.description }}
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    <v-chip label>{{ row.serial_no }}</v-chip>
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    {{ row.model }}
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    {{ row.brand }}
                  </td>
                  <td height="98px" class="simple-table-td" width="100">
                    {{ row.location }}
                  </td>
                  <td
                    height="98px"
                    v-on:click.stop.prevent
                    class="simple-table-td"
                    width="100"
                  >
                    <div v-if="hover">
                      <v-btn
                        icon
                        color="red lighten-1"
                        v-on:click.stop.prevent="open_delete_dailog(row)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </div>
                    <template v-else>
                      <v-chip
                        class="mx-2 my-1 custom-grey-border"
                        color="blue-grey darken-1"
                        text-color="white"
                      >
                        <v-avatar left>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-avatar>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ row.added_user_display_name }}
                        </p>
                      </v-chip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            class="mx-2 my-1 custom-grey-border"
                            color="blue-grey darken-1"
                            text-color="white"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-avatar left>
                              <v-icon>mdi-clock-outline</v-icon>
                            </v-avatar>
                            <p class="m-0 custom-nowrap-ellipsis">
                              {{ row.created_at }}
                            </p>
                          </v-chip>
                        </template>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ formatDateTime(row.added_at) }}
                        </p>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no equipment at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <Dialog :common-dialog="new_equipment_dailog" :dialog-width="dialogWidth">
      <template v-slot:title> New Equipment </template>
      <template v-slot:body>
        <v-container fluid>
          <v-form
            ref="equipmentForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-row>
              <v-col md="3" class="my-auto">
                <v-img
                  v-if="equipment.image"
                  :lazy-src="$defaultImage"
                  :src="equipment.image"
                  max-height="200"
                  max-width="200"
                  height="200px"
                  width="200px"
                  class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                >
                </v-img>
                <v-img
                  v-else
                  :lazy-src="$defaultImage"
                  :src="$defaultImage"
                  max-height="200"
                  max-width="200"
                  height="200px"
                  width="200px"
                  class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                >
                </v-img>
                <div class="margin-auto">
                  <v-btn
                    class="my-4 custom-bold-button width-100"
                    outlined
                    small
                    v-on:click="select_image()"
                    color="cyan"
                    :loading="image_loading"
                    :disabled="image_loading"
                  >
                    <template v-if="equipment.image">Change Image</template>
                    <template v-else>Upload Image</template>
                  </v-btn>
                  <div class="d-none">
                    <v-file-input
                      ref="uploadImage"
                      v-on:change="upload_image"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/jpg"
                    ></v-file-input>
                  </div>
                </div>
              </v-col>
              <v-col md="9">
                <v-layout>
                  <v-flex md6 v-if="!isCustomer" class="mx-3 my-2">
                    <label
                      for="customer-equipment"
                      class="font-weight-700 font-size-16 required"
                      >Customer</label
                    >
                    <v-autocomplete
                      hide-details
                      id="customer-equipment"
                      v-model.trim="equipment.customer"
                      :items="all_customers"
                      dense
                      flat
                      filled
                      label="Customer"
                      item-color="cyan"
                      color="cyan"
                      solo
                      :rules="[
                        validateRules.required(equipment.customer, 'Customer'),
                      ]"
                      :disabled="form_loading"
                      :loading="form_loading"
                      item-text="display_name"
                      item-value="id"
                      v-on:change="filter_property()"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Customer(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(item.profile_logo)"
                            aspect-ratio="1"
                            class="margin-auto"
                            transition="fade-transition"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.display_name }}</v-list-item-title
                          >
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.company_name }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action
                          v-if="item.default_person"
                          class="align-self-center"
                        >
                          <v-list-item-subtitle
                            class="text-lowercase pb-2 font-weight-500 font-size-14"
                            >{{
                              item.default_person.primary_email
                            }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            class="text-lowercase font-weight-500 font-size-14"
                            >{{
                              item.default_person.primary_phone
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="property-equipment"
                      class="font-weight-700 font-size-16 required"
                      >Property</label
                    >
                    <v-autocomplete
                      hide-details
                      id="property-equipment"
                      :items="filtered_property"
                      v-model.trim="equipment.property"
                      dense
                      flat
                      filled
                      label="Property"
                      item-color="cyan"
                      color="cyan"
                      solo
                      :disabled="form_loading"
                      :loading="form_loading"
                      :rules="[
                        validateRules.required(equipment.property, 'Property'),
                      ]"
                      item-text="barcode"
                      item-value="id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Property(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-icon class="mr-3">
                          <v-icon large>mdi-home-map-marker</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-layout style="max-width: 150px">
                            <v-flex>
                              <Barcode
                                class="my-0 mt-0 mb-0 mr-1"
                                :barcode="item.barcode"
                              ></Barcode>
                            </v-flex>
                            <v-flex v-if="item.is_tenant">
                              <v-chip
                                class="mx-1"
                                label
                                small
                                color="green"
                                outlined
                                >Tenant</v-chip
                              >
                            </v-flex>
                            <v-flex>
                              <v-chip
                                label
                                small
                                class="ml-1"
                                :color="item.type == 1 ? 'cyan' : 'orange'"
                                outlined
                              >
                                <template v-if="item.type == 1"
                                  >Property</template
                                >
                                <template v-else>Billing</template>
                              </v-chip>
                            </v-flex>
                          </v-layout>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ get_formatted_property(item) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md6 v-if="isCustomer" class="mx-3 my-2"> </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="name-equipment"
                      class="font-weight-700 font-size-16 required"
                      >Name</label
                    >
                    <v-text-field
                      id="name-equipment"
                      v-model.trim="equipment.name"
                      dense
                      filled
                      label="Name"
                      solo
                      flat
                      :disabled="form_loading"
                      :loading="form_loading"
                      color="cyan"
                      :rules="[validateRules.required(equipment.name, 'Name')]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="serial-no-equipment"
                      class="font-weight-700 font-size-16 required"
                      >Serial No.</label
                    >
                    <v-text-field
                      id="serial-no-equipment"
                      v-model.trim="equipment.serial_no"
                      dense
                      filled
                      label="Serial No."
                      solo
                      flat
                      :disabled="form_loading"
                      :loading="form_loading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          equipment.serial_no,
                          'Serial No.'
                        ),
                      ]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12 class="mx-3 my-2">
                    <label class="font-weight-700 font-size-16"
                      >Description</label
                    >
                    <v-textarea
                      dense
                      filled
                      label="Description"
                      solo
                      flat
                      row-height="20"
                      auto-grow
                      color="cyan"
                      v-model.trim="equipment.description"
                      :rules="[
                        validateRules.minLength(
                          equipment.description,
                          'Description',
                          1
                        ),
                        validateRules.maxLength(
                          equipment.description,
                          'Description',
                          1024
                        ),
                      ]"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="model-equipment"
                      class="font-weight-700 font-size-16"
                      >Model</label
                    >
                    <v-text-field
                      id="model-equipment"
                      v-model.trim="equipment.model"
                      dense
                      filled
                      label="Model"
                      solo
                      flat
                      :disabled="form_loading"
                      :loading="form_loading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="brand-equipment"
                      class="font-weight-700 font-size-16"
                      >Brand</label
                    >
                    <v-autocomplete
                      hide-details
                      v-model.trim="equipment.brand"
                      id="brand-equipment"
                      :items="brands"
                      dense
                      flat
                      filled
                      label="Brand"
                      item-color="cyan"
                      color="cyan"
                      solo
                      :disabled="form_loading"
                      :loading="form_loading"
                      item-text="text"
                      item-value="id"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manage_brand = true"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Brand(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md6 class="mx-3 my-2">
                    <label
                      for="location-equipment"
                      class="font-weight-700 font-size-16"
                      >Location</label
                    >
                    <v-autocomplete
                      hide-details
                      id="location-equipment"
                      :items="equipment_locations"
                      v-model.trim="equipment.location"
                      dense
                      flat
                      filled
                      label="Location"
                      item-color="cyan"
                      color="cyan"
                      solo
                      :disabled="form_loading"
                      :loading="form_loading"
                      item-text="text"
                      item-value="id"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manage_location = true"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Location(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md6 class="mx-3 my-2 text-right">
                    <v-btn
                      v-if="equipment.uuid"
                      :disabled="form_loading || !formValid"
                      class="mt-6 mr-4 custom-grey-border custom-bold-button"
                      v-on:click="cancel_update()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="cyan white--text"
                      :disabled="form_loading || !formValid"
                      class="mt-6 custom-grey-border custom-bold-button"
                      v-on:click="add_equipment()"
                    >
                      <template v-if="equipment.uuid">Update</template>
                      <template v-else>Add</template>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <template
                  v-if="$hasWarranty && getPermission('warranty:create')"
                >
                  <v-layout>
                    <v-flex md12>
                      <v-checkbox
                        dense
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 width-100"
                        color="cyan"
                        v-model.trim="equipment.warranty"
                      >
                        <template v-slot:label>
                          <label class="m-0 width-100">Warranty</label>
                        </template>
                      </v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout class="width-50" v-if="equipment.warranty">
                    <v-flex md5>
                      <v-text-field
                        id="model-warranty-duration"
                        v-model.trim="equipment.warranty_duration"
                        dense
                        filled
                        label="Duration"
                        solo
                        v-mask="'###'"
                        flat
                        :disabled="form_loading"
                        :loading="form_loading"
                        color="cyan"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md7>
                      <v-select
                        :items="warrantyDurationTypes"
                        dense
                        filled
                        color="cyan"
                        hide-details
                        solo
                        flat
                        item-color="cyan"
                        class="width-100"
                        v-model.trim="equipment.warranty_type"
                        label="Type"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="simple-table-th" align="center" width="100">
                        Image
                      </th>
                      <th
                        class="simple-table-th"
                        v-if="!isCustomer"
                        width="200"
                      >
                        Customer
                      </th>
                      <th class="simple-table-th">Property</th>
                      <th class="simple-table-th" width="100">Name</th>
                      <th class="simple-table-th" width="100">Description</th>
                      <th class="simple-table-th" width="100">Serial No.</th>
                      <th class="simple-table-th" width="100">Model</th>
                      <th class="simple-table-th" width="100">Brand</th>
                      <th class="simple-table-th" width="100">Location</th>
                      <th class="simple-table-th" width="50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="equipments.length">
                      <tr
                        v-for="(row, index) in equipments"
                        :key="index"
                        v-on:click="updateIntRow(row)"
                      >
                        <td align="center" class="simple-table-td" width="100">
                          <template v-if="row.image">
                            <v-img
                              class="customer-image"
                              width="70"
                              height="70"
                              contain
                              :lazy-src="$defaultImage"
                              :src="row.image"
                            >
                            </v-img>
                          </template>
                          <template v-else>
                            <v-img
                              class="customer-image"
                              width="70"
                              height="70"
                              contain
                              :lazy-src="$defaultImage"
                              :src="$defaultImage"
                            >
                            </v-img>
                          </template>
                        </td>
                        <td
                          v-if="!isCustomer"
                          class="simple-table-td"
                          width="200"
                        >
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Display Name: </b>
                            {{ getCustomer(row.customer, "display_name") }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Company: </b>
                            {{ getCustomer(row.customer, "company_name") }}
                          </p>
                        </td>
                        <td class="simple-table-td">
                          {{ getAddress(row.property, "unit_no") }}
                          {{ getAddress(row.property, "street_1") }}
                          {{ getAddress(row.property, "street_2") }}
                          {{ getAddress(row.property, "zip_code") }}
                        </td>
                        <td class="simple-table-td" width="100">
                          {{ row.name }}
                        </td>
                        <td class="simple-table-td" width="100">
                          {{ row.description }}
                        </td>
                        <td class="simple-table-td" width="100">
                          <v-chip label>{{ row.serial_no }}</v-chip>
                        </td>
                        <td class="simple-table-td" width="100">
                          {{ row.model }}
                        </td>
                        <td class="simple-table-td" width="100">
                          {{ getBrand(row.brand) }}
                        </td>
                        <td class="simple-table-td" width="100">
                          {{ getLocation(row.location) }}
                        </td>
                        <td
                          class="simple-table-td"
                          width="50"
                          align="center"
                          v-on:click.stop.prevent
                        >
                          <v-btn
                            icon
                            color="red lighten-1"
                            v-on:click.stop.prevent="remove_row(index)"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td :colspan="columnCount">
                        <p
                          class="m-0 row-not-found text-center font-weight-500 font-size-16"
                        >
                          <img
                            :src="$assetURL('media/error/empty.png')"
                            class="row-not-found-image"
                          />
                          Uhh... There are no equipment at the moment.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="form_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="updateOrCreate()"
        >
          Save
        </v-btn>
        <v-btn
          :disabled="form_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="closeDialog()"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="delete_dailog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_row.name }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_row.name }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_dailog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="delete_confirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <ManageBrand
      :dialog="manage_brand"
      :brand="brands"
      v-on:close-dialog="manage_brand = false"
      v-on:get-product-brand="get_options"
    ></ManageBrand>
    <ManageEquipmentLocation
      :dialog="manage_location"
      :equipment="equipment_locations"
      v-on:close-dialog="manage_location = false"
      v-on:get-equipment-location="get_options"
    ></ManageEquipmentLocation>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { QUERY, GET, POST, DELETE } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
// import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      delete_dailog: false,
      delete_loading: false,
      delete_row: {},
      new_equipment_dailog: false,
      all_customers: [],
      all_property: [],
      brands: [],
      equipment_locations: [],
      filtered_property: [],
      equipments: [],
      equipment: {
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
        description: "",
      },
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    customerId(param) {
      this.equipment.customer = param;
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.get_all_equipment();
          }
        }
      },
    },
  },
  components: {
    // TableActivity,
    Dialog,
    ManageBrand,
    ManageEquipmentLocation,
    DeleteDialog,
    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    delete_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: "equipment",
          data: { ids: [this.delete_row.id] },
        })
        .then(() => {
          this.delete_dailog = false;
          this.delete_row = {};
          this.get_all_equipment();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    open_delete_dailog(row) {
      this.delete_dailog = true;
      this.delete_row = row;
    },
    show_detail(row) {
      const brand = this.lodash.find(this.brands, { text: row.brand });

      const location = this.lodash.find(this.equipment_locations, {
        text: row.location,
      });

      const equipment = {
        id: row.id,
        uuid: uuidv4(),
        image: row.image,
        customer: row.customer,
        property: row.property,
        name: row.name,
        serial_no: row.serial_no,
        model: row.model,
        brand: brand ? brand.id : 0,
        location: location ? location.id : 0,
        warranty: row.warranty,
        warranty_duration: row.warranty_duration,
        warranty_type: row.warranty_type,
        description: row.description,
      };

      this.equipment = this.lodash.cloneDeep(equipment);
      // this.equipments.push(equipment);
      this.new_equipment_dailog = true;
      this.$nextTick(() => {
        this.filter_property();
      });
    },
    remove_row(index) {
      this.equipments.splice(index, 1);
    },
    updateIntRow(row) {
      this.equipment = this.lodash.cloneDeep(row);
    },
    getCustomer(id, key) {
      const customer = this.lodash.find(this.all_customers, { id });
      if (customer) {
        return customer[key];
      }
      return "N/A";
    },
    getAddress(id, key) {
      const property = this.lodash.find(this.all_property, { id });
      if (property) {
        return property[key];
      }
      return "N/A";
    },
    getBrand(id) {
      const brand = this.lodash.find(this.brands, { id });
      if (brand) {
        return brand.text;
      }
      return "N/A";
    },
    getLocation(id) {
      const location = this.lodash.find(this.equipment_locations, { id });
      if (location) {
        return location.text;
      }
      return "N/A";
    },
    cancel_update() {
      this.equipment = new Object({
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
      });

      if (this.customerId) {
        this.equipment.customer = this.customerId;
      }
    },
    add_equipment() {
      if (!this.$refs.equipmentForm.validate()) {
        return false;
      }

      const equipmentIndex = this.lodash.findIndex(this.equipments, {
        uuid: this.equipment.uuid,
      });
      if (equipmentIndex >= 0) {
        this.equipments.splice(equipmentIndex, 1, this.equipment);
      } else {
        this.equipment.uuid = uuidv4();
        this.equipments.push(this.equipment);
      }

      this.$nextTick(() => {
        this.equipment = new Object({
          id: null,
          uuid: null,
          image: null,
          customer: null,
          property: null,
          name: null,
          serial_no: null,
          model: null,
          brand: null,
          location: null,
          warranty: 0,
          warranty_duration: null,
          warranty_type: null,
        });

        if (this.customerId) {
          this.equipment.customer = this.customerId;
        }
      });
    },
    updateOrCreate() {
      if (this.form_loading) {
        return false;
      }

      if (!this.equipments.length) {
        ErrorEventBus.$emit(
          "update:error",
          "Please add atleast one equipment."
        );
        return false;
      }

      this.form_loading = true;

      this.$store
        .dispatch(POST, {
          url: "equipment",
          data: { equipments: this.equipments },
        })
        .then(() => {
          this.closeDialog();
          this.get_all_equipment();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    closeDialog() {
      this.new_equipment_dailog = false;
      this.equipments = [];
      this.equipment = new Object({
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
      });
      if (this.customerId) {
        this.equipment.customer = this.customerId;
      }
    },
    select_image() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    upload_image(param) {
      const _this = this;
      _this.image_loading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          const image = _this.lodash.head(response);
          _this.equipment.image = image.file ? image.file.url : null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.image_loading = false;
        });
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    new_equipment() {
      this.new_equipment_dailog = true;
      this.filter_property();
    },
    filter_property() {
      this.filtered_property = this.lodash.filter(this.all_property, {
        customer: this.equipment.customer,
      });
    },
    get_options() {
      this.$store
        .dispatch(GET, { url: "equipment/options" })
        .then(({ data }) => {
          this.all_customers = data.customers;
          this.all_property = data.properties;
          this.brands = data.brands;
          this.equipment_locations = data.equipment_locations;
          this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.equipment.customer,
          });
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.get_all_equipment();
    },
    get_all_equipment() {
      const _this = this;

      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "equipment",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    _this.get_options();

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_equipment();
    });

    _this.$nextTick(() => {
      _this.equipment.customer = _this.customerId;
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      if (this.isCustomer) {
        --result;
      }
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
