import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data: function data() {
    return {
      parent: 0,
      customer: 0,
      customerPerson: 0,
      customerBilling: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      customerPersonDialog: false,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null
      },
      timeout: null,
      timeoutLimit: 500
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: function _default() {
        return new Object();
      }
    },
    type: {
      type: String,
      required: true,
      default: "product"
    }
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);

        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);

            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }

          this.getAllQuotations();
        }
      }
    }
  },
  components: {
    Barcode: Barcode,
    CustomerPersonDialog: CustomerPersonDialog,
    ListingTable: ListingTable
  },
  methods: {
    resetAll: function resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog: function closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson: function selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();

      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty: function selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation: function checkQuotation() {
      var _this = this;

      if (_this.customer > 0 && _this.customerPerson > 0 && _this.customerBilling > 0) {
        var queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling
        });
        queryObject[_this.type] = _this.parent;

        _this.$router.push(_this.getDefaultRoute("quotation.create", {
          query: queryObject
        }));
      }
    },
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = [];
          break;
      }

      _this.getAllQuotations();
    },
    getAllQuotations: function getAllQuotations() {
      var _this = this;

      var filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit("update:error", InitializeError("End Date must be after Start Date."));
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store.dispatch(QUERY, {
          url: "quotation",
          data: filter
        }).then(function (_ref) {
          var data = _ref.data;
          _this.dataRows = data.rows;
          _this.statusList = data.status_list;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.dataLoading = false;
        });
      }, _this.timeoutLimit);
    }
  }
};